import { useMemo } from 'react';

import { WalletNetwork } from '@api';

import { useAuth } from '@store:web/components/providers/AuthProvider';

const useUserWalletStatusFlags = () => {
  const { user } = useAuth();

  return useMemo(() => {
    if (!user?.wallets?.length) {
      return {
        isEMVWalletConnected: false,
        isSolanaWalletConnected: false,
      };
    }

    return user.wallets.reduce(
      (acc, wallet) => {
        const networksMap: Record<
          WalletNetwork,
          'isEMVWalletConnected' | 'isSolanaWalletConnected' | 'isTonWalletConnected'
        > = {
          EVM: 'isEMVWalletConnected',
          SOLANA: 'isSolanaWalletConnected',
          TON: 'isTonWalletConnected',
        };
        const connectedWallet = networksMap[wallet.attributes.network];
        acc[connectedWallet] = true;

        return acc;
      },
      {
        isEMVWalletConnected: false,
        isSolanaWalletConnected: false,
        isTonWalletConnected: false,
      },
    );
  }, [user]);
};

export default useUserWalletStatusFlags;
