import { ComponentPropsWithoutRef, ComponentType, ReactNode, useMemo } from 'react';

import classNames from 'classnames';

import { useSqrTokenPriceQuery } from '@query';

import Amount from '@uikit/icons/Amount';
import { GoldenStar } from '@uikit/icons/GoldenStar';
import Label from '@uikit/icons/Label';
import { Link as LinkIcon } from '@uikit/icons/Link';
import { MsqToken } from '@uikit/icons/MsqToken';
import MultiColorFire from '@uikit/icons/MultiColorFire';

import { HotOffer } from '@shared/api/types/hotOffers';
import NextLink from '@shared/common/components/NextLink';
import { HOT_OFFER_TYPE } from '@shared/constants';
import { formatLargeAmount } from '@shared/helpers/formatters';
import { truncateDecimals } from '@shared/helpers/formatters';

import useCheckRequiredSelfData from '@store:web/utils/hooks/useCheckRequiredSelfData';

export interface HotOfferStatsProps extends ComponentPropsWithoutRef<'div'> {
  offer: HotOffer;
}

interface HotOfferStatItem {
  id: string;
  icon: ReactNode;
  content: ReactNode;
}

export const USDT_SQR_EXCH_CURRENCY = 'USDT-SQR-EXCH';

const OfferAmountSqrTokenIcon = ({ className }: { className?: string }) => (
  <MsqToken className={className} />
);
const OfferAmountAnyTokenIcon = ({ className }: { className?: string }) => (
  <Amount className={classNames('text-secondary-700', className)} />
);
const ClaimLimitIcon = ({ className }: { className?: string }) => (
  <MultiColorFire className={className} />
);
const PercentageIcon = ({ className }: { className?: string }) => (
  <Label className={classNames('text-primary-600', className)} />
);
const PoolIcon = ({ className }: { className?: string }) => (
  <Amount className={classNames('text-secondary-700', className)} />
);

export const useHotOfferStats = (offer?: HotOffer) => {
  const { check } = useCheckRequiredSelfData();

  const {
    data: sqrTokenPrice,
    isLoading: isSqrTokenPriceLoading,
    isError: isSqrTokenPriceError,
  } = useSqrTokenPriceQuery({
    enabled: offer?.attributes.offerCurrency === USDT_SQR_EXCH_CURRENCY,
  });

  return useMemo(() => {
    if (!offer) {
      return [];
    }

    const {
      type,
      attributes: {
        offerAmount,
        cashBackPercentage,
        poolAmount,
        usedPoolAmount,
        offerCurrency,
        claimsLimit,
        claimsCounted,
        rewardPoolId,
      } = {},
    } = offer;

    const isRewardPool = Boolean(rewardPoolId);

    const offerStats: {
      id: string;
      Icon?: ComponentType<{ className?: string }>;
      icon: ReactNode;
      content: ReactNode;
    }[] = [];

    if (isRewardPool) {
      const rewardPoolLinkLabel = offer?.included?.find((value) => value.id === rewardPoolId);

      offerStats.push({
        id: 'reward-pool',
        Icon: GoldenStar,
        icon: <GoldenStar className="icon-sm" />,
        content: (
          <span className="whitespace-nowrap">
            {offerAmount} {offerCurrency}
          </span>
        ),
      });

      offerStats.push({
        id: 'reward-pool',
        icon: null,
        content: (
          <div className="max-w-[170px] flex justify-end gap-1">
            <NextLink
              className="text-primary-600 truncate whitespace-nowrap overflow-hidden text-ellipsis font-normal text-base"
              href={`/reward-pools/${rewardPoolId}`}
              onClick={(event) => {
                if (!check({ auth: true })) {
                  event.preventDefault();

                  return;
                }
              }}
            >
              {rewardPoolLinkLabel?.attributes?.name || 'Reward Pool'}
            </NextLink>
            <LinkIcon className="min-w-4 min-h-4 w-4 text-primary-600" />
          </div>
        ),
      });

      return offerStats;
    }

    if (([HOT_OFFER_TYPE.OFFER, HOT_OFFER_TYPE.SO_QUEST_OFFER] as string[]).includes(type)) {
      if (offerCurrency === USDT_SQR_EXCH_CURRENCY) {
        let content: ReactNode = '';

        if (isSqrTokenPriceLoading) {
          content = 'Loading...';
        } else if (sqrTokenPrice && offerAmount) {
          content = (
            <>
              <span>
                {+truncateDecimals(Number(offerAmount) / sqrTokenPrice.attributes.price, 2)} SQR
              </span>
              <span className="tag text-inherit bg-neutral-100 text-neutral-500">
                ≈ {'$'}
                {offerAmount}
              </span>
            </>
          );
        } else if (isSqrTokenPriceError) {
          content = 'Error';
        }

        offerStats.push({
          id: 'offer-amount',
          Icon: OfferAmountSqrTokenIcon,
          icon: <OfferAmountSqrTokenIcon className="icon-sm" />,
          content,
        });
      } else {
        offerStats.push({
          id: 'offer-amount',
          Icon: OfferAmountAnyTokenIcon,
          icon: <OfferAmountAnyTokenIcon className="icon-sm" />,
          content: `${offerAmount || 0} ${offerCurrency ? offerCurrency : ''}`,
        });
      }

      if (claimsLimit !== null) {
        offerStats.push({
          id: 'claim-limit',
          icon: <ClaimLimitIcon className="icon-sm" />,
          Icon: ClaimLimitIcon,
          content: `${Math.min(claimsCounted || 0, claimsLimit || 0)}/${claimsLimit || 0}`,
        });
      }

      return offerStats;
    }

    if (type === HOT_OFFER_TYPE.BINANCE) {
      return [
        {
          id: 'percentage',
          icon: <PercentageIcon className="icon-sm" />,
          Icon: PercentageIcon,
          content: `${cashBackPercentage || 0}%`,
        },
        {
          id: 'pool',
          icon: <PoolIcon className="icon-sm" />,
          Icon: PoolIcon,
          content: `${formatLargeAmount(
            Math.min(usedPoolAmount || 0, poolAmount || 0),
          )}/${formatLargeAmount(poolAmount || 0)}`,
        },
      ];
    }

    return [];
  }, [offer, sqrTokenPrice, isSqrTokenPriceError, isSqrTokenPriceLoading, check]);
};

export default function HotOfferStats({ className, offer }: HotOfferStatsProps) {
  const stats: HotOfferStatItem[] = useHotOfferStats(offer);

  if (!stats.length) {
    return null;
  }

  return (
    <ul className={classNames('w-full flex items-center justify-between gap-3', className)}>
      {stats.map(({ icon, content, id }) => (
        <li
          key={id}
          className="sm:text-sm md:text-lg font-semibold inline-flex items-center gap-1 overflow-hidden text-ellipsis whitespace-nowrap"
          data-qa="hot-offer-container-stat-item"
        >
          {icon} {content}
        </li>
      ))}
    </ul>
  );
}
